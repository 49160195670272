import React from 'react'

const MessagePSideNav = () => {
    return (
        <div id='sidenav'>
            <div id="hambar">
                <ul>
                    <li id='hambar2' >
                        <svg width="454" height="454" viewBox="0 0 454 454" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.375 85.125V141.875H425.625V85.125H28.375ZM28.375 198.625V255.375H425.625V198.625H28.375ZM28.375 312.125V368.875H425.625V312.125H28.375Z" fill="#504F4F" />
                        </svg>
                        <span>I.O Tech Nepal</span>
                    </li>
                    <li>
                        <svg width="469" height="469" viewBox="0 0 469 469" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M78.1667 254.042H195.417C200.599 254.042 205.57 251.983 209.235 248.318C212.899 244.653 214.958 239.683 214.958 234.5V78.1667C214.958 72.9839 212.899 68.0134 209.235 64.3486C205.57 60.6838 200.599 58.625 195.417 58.625H78.1667C72.9839 58.625 68.0134 60.6838 64.3486 64.3486C60.6838 68.0134 58.625 72.9839 58.625 78.1667V234.5C58.625 239.683 60.6838 244.653 64.3486 248.318C68.0134 251.983 72.9839 254.042 78.1667 254.042ZM58.625 390.833C58.625 396.016 60.6838 400.987 64.3486 404.651C68.0134 408.316 72.9839 410.375 78.1667 410.375H195.417C200.599 410.375 205.57 408.316 209.235 404.651C212.899 400.987 214.958 396.016 214.958 390.833V312.667C214.958 307.484 212.899 302.513 209.235 298.849C205.57 295.184 200.599 293.125 195.417 293.125H78.1667C72.9839 293.125 68.0134 295.184 64.3486 298.849C60.6838 302.513 58.625 307.484 58.625 312.667V390.833ZM254.042 390.833C254.042 396.016 256.101 400.987 259.765 404.651C263.43 408.316 268.401 410.375 273.583 410.375H390.833C396.016 410.375 400.987 408.316 404.651 404.651C408.316 400.987 410.375 396.016 410.375 390.833V254.042C410.375 248.859 408.316 243.888 404.651 240.224C400.987 236.559 396.016 234.5 390.833 234.5H273.583C268.401 234.5 263.43 236.559 259.765 240.224C256.101 243.888 254.042 248.859 254.042 254.042V390.833ZM273.583 195.417H390.833C396.016 195.417 400.987 193.358 404.651 189.693C408.316 186.028 410.375 181.058 410.375 175.875V78.1667C410.375 72.9839 408.316 68.0134 404.651 64.3486C400.987 60.6838 396.016 58.625 390.833 58.625H273.583C268.401 58.625 263.43 60.6838 259.765 64.3486C256.101 68.0134 254.042 72.9839 254.042 78.1667V175.875C254.042 181.058 256.101 186.028 259.765 189.693C263.43 193.358 268.401 195.417 273.583 195.417Z" fill="#504F4F" />
                        </svg>
                        <span>Dashboard</span>
                    </li>
                    <li>
                        <svg width="469" height="469" viewBox="0 0 469 469" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M166.104 351.75L234.5 429.916L302.896 351.75H371.292C392.846 351.75 410.375 334.221 410.375 312.666V78.1663C410.375 56.6119 392.846 39.083 371.292 39.083H97.7083C76.1539 39.083 58.625 56.6119 58.625 78.1663V312.666C58.625 334.221 76.1539 351.75 97.7083 351.75H166.104ZM136.792 136.791H332.208V175.875H136.792V136.791ZM136.792 214.958H273.583V254.041H136.792V214.958Z" fill="#504F4F" />
                        </svg>

                        <span>Message</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MessagePSideNav
